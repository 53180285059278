import Termsconditions from '../pages/TermsConditionsPage/Termsconditions';
import AddChunaPage from '../pages/AddChunaPage/AddChunaPage';
import ChunaPage from '../pages/ChunaPage/ChunaPage';
import DashboardPage from '../pages/DashboardPage/DashboardPage';
import EditChunaPage from '../pages/EditChunaPage/EditChunaPage';
import HistoryPage from '../pages/HistoryPage/HistoryPage';
import AddMawasPage from '../pages/MawaAddPage/AddMawasPage';
import EditMawaPackPage from '../pages/MawaAddPage/EditMawaPage';
import MawaPages from '../pages/MawaAddPage/MawaPage';
import AddMavaPage from '../pages/MawaPage/AddMavaPage';
import EditMawaPage from '../pages/MawaPage/EditMawaPage';
import MawaPage from '../pages/MawaPage/MawaPage';
import OnHoldPage from '../pages/OnHoldPage/OnHoldPage';
import ViewOnHoldPage from '../pages/OnHoldPage/ViewOnHoldPage';
import OrderPage from '../pages/OrderPage/OrderPage';
import ViewOrderPage from '../pages/OrderPage/ViewOrderPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage/PrivacyPolicyPage';
import UserListPage from '../pages/UserListPage/UserListPage';
import PrivateRoute from './RouteProtection';
import ContactUs from '../pages/ContactUsPage/ContactUs';

const routes = [
  {
    title : "Dashboard",
    path: '/dashboard',
    element: (
      <PrivateRoute>
        <DashboardPage />
      </PrivateRoute>
    ),
  },

  {
    title : "Chuna",
    path: '/chuna',
    element: (
      <PrivateRoute>
        <ChunaPage />
      </PrivateRoute>
    ),
  },

  {
    title : "Add Chuna",
    path: '/add-chuna',
    element: (
      <PrivateRoute>
        <AddChunaPage />
      </PrivateRoute>
    ),
  },

  {
    title : "Edit Chuna",
    path: '/edit-chuna/:id',
    element: (
      <PrivateRoute>
        <EditChunaPage />
      </PrivateRoute>
    ),
  },


  {
    title : "Mawa Pack",
    path: '/mawa-pack',
    element: (
      <PrivateRoute>
        <MawaPage />
      </PrivateRoute>
    ),
  },


  {
    title : "Add Mawa Pack",
    path: '/mawa-pack-add',
    element: (
      <PrivateRoute>
        <AddMavaPage />
      </PrivateRoute>
    ),
  },
  {
    title : "Edit Mawa Pack",
    path: '/mawa-pack-edit/:id',
    element: (
      <PrivateRoute>
        <EditMawaPage />
      </PrivateRoute>
    ),
  },

  {
    title : "Mawa",
    path: '/add-mawa',
    element: (
      <PrivateRoute>
        <MawaPages/>
      </PrivateRoute>
    ),
  },
  

  {
    title : "Add Mawa",
    path: '/add-mawa-add',
    element: (
      <PrivateRoute>
        <AddMawasPage/>
      </PrivateRoute>
    ),
  },


  {
    title : 'Edit Mawa',
    path: '/add-mawa-edit/:id',
    element: (
      <PrivateRoute>
        <EditMawaPackPage/>
      </PrivateRoute>
    ),
  },


  {
    title : "Orders",
    path: '/order-pending',
    element: (
      <PrivateRoute>
        <OrderPage/>
      </PrivateRoute>
    ),
  },

  {
    title : "View Order",
    path: '/view-order-pending/:id',
    element: (
      <PrivateRoute>
        <ViewOrderPage/>
      </PrivateRoute>
    ),
  },

  {
    title : "History",
    path: '/history',
    element: (
      <PrivateRoute>
        <HistoryPage/>
      </PrivateRoute>
    ),
  },

  {
    title : "User Detail",
    path: '/user-details',
    element: (
      <PrivateRoute>
        <UserListPage/>
      </PrivateRoute>
    ),
  },

  {
    title : "On Hold",
    path: '/onhold',
    element: (
      <PrivateRoute>
        <OnHoldPage/>
      </PrivateRoute>
    ),
  },

  {
    title : "View On Hold",
    path: '/view-onhold/:id',
    element: (
      <PrivateRoute>
        <ViewOnHoldPage/>
      </PrivateRoute>
    ),
  },
  {
    title : "Privacy Policy",
    path: '/privacy-policy',
    element: (
      <PrivateRoute>
        <PrivacyPolicyPage/>
      </PrivateRoute>
    ),
  },
  {
    title : "Terms Conditions",
    path: '/terms-conditions',
    element: (
      <PrivateRoute>
        <Termsconditions/>
      </PrivateRoute>
    ),
  },
  {
    title : "Contact-us",
    path: '/Contact-us',
    element: (
      <PrivateRoute>
        <ContactUs/>
      </PrivateRoute>
    ),
  },

];

export default routes;
