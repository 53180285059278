import React, { useEffect, useState } from "react";
import Processing from "../../components/Processing/Processing";
import { fetchDataFromAPI } from "../../utils/api";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { toast } from "react-toastify";


const ChunaPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);


  const [showDeleteModal , setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null);
  // this use Effect for delete id
  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
    setShowDeleteModal(false);
    }
  }, [deleteId]);

  useEffect(() => {
    setLoading(true);

    getAdmins();
  }, []);

  const getAdmins = () => {
    fetchDataFromAPI("mawa/get-all-chuna", "get", "", "")
      .then((res) => {
        setData(res?.data);
        setLoading(false);
        console.log("res", res?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };


      // hide delete modal function
      const hideDeleteModal = () => {
        setDeleteId(null);
      };
    
      // api for delete
      const deleteContent = () => {
       setLoading(true);
        fetchDataFromAPI(`mawa/delete-chuna?id=${deleteId}`, "delete", "", "")
          .then((res) => {
            toast.success(res?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });
            getAdmins();
            setDeleteId(null);
            setShowDeleteModal(false);
           setLoading(false);
          })
          .catch((error) => {
            console.log("Content error: ", error);
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-danger text-white",
            });
          setLoading(false);
          });
      };


  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Chuna</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <form className="mx-3">
          <div className="row mb-3"></div>
        </form>
        <div className="card-body">
          <div className="d-flex  ">
            <h3 className=" font-weight-bold ">Chuna List</h3>
            <div className="col d-flex justify-content-end  ">
              <button
                type="submit"
                className="btn-custom-btn"
                onClick={() => navigate("/add-chuna")}
              >
                Add Chuna
              </button>
            </div>
          </div>
          <hr />
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap  align-items-end table-hover">
              <thead>
                <tr className="">
                  <th scope="col">No</th>
                  <th scope="col">Image</th>
                  <th scope="col">Chuna Name</th>
                  <th scope="col">Chuna Price</th>
                  <th scope="col">Chuna Quantity</th>
                  <th scope="col">Action</th>

                  {/* <th scope="col">View</th> */}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr className="align-middle">
                      <th>{index + 1}</th>
                      <td>
                        <img
                          className="border border-2 border-primary"
                          src={item?.chuna_image}
                          alt="Profile Image"
                          style={{
                            height: 100,
                            width: 100,
                            borderRadius: 25,
                          }}
                        />
                      </td>
                      <td>{item?.chuna_name}</td>
                      <td>₹{item?.chuna_price}</td>
                      <td>{item?.chuna_qty}</td>

                      <td className="">
                        <div className="d-flex">
                          <div
                            className="edit-icon  me-2"
                            role="button"
                            onClick={() =>
                              navigate(`/edit-chuna/${item?._id}`)
                            }
                          >
                            <i class="bi bi-pencil-square"></i>
                          </div>
                          <div
                            role="button"
                            className="delete-icon btn btn-sm btn-danger"
                            onClick={() => {
                              setDeleteId(item?._id);
                            }}
                          >
                            <i class="bi bi-trash"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <!-- End Default Table Example --> */}
        </div>
        <DeleteModal
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Chuna"
          body="Are you sure you want to delete this record?"
        />
      </div>
    </div>
  );
};

export default ChunaPage;
