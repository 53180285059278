import React from "react";

const CommonButton = ({ loading, buttonText ,onClick }) => {
  return (
    <div className="col-12">
      <button className="btn-custom-btn  w-100" type="submit" onClick={onClick}>
        {/* <span className="indicator-label">{buttonText}</span> */}

        {!loading && <span className="indicator-label">{buttonText}</span>}
        {loading && (
          <span className={`indicator-progress ${loading ? "show" : ""}`}>
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
    </div>
  );
};

export default CommonButton;
