import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const { pathname } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSubIndex, setSelectedSubIndex] = useState(null);
  const [isDiamondOpened, setIsDiamondOpened] = useState(false);
  const [isPackageOpened, setIsPackageOpened] = useState(false);
  const routes = [
    { title: "Dashboard", path: "/dashboard" },
    {
      title: "Orders",
      content: [
        { title: "Pending", path: "/order-pending" },
        { title: "On Hold", path: "/onhold" },

      ],
    },
    // { title: "Chuna", path: "/chuna" },
    { title: "Mawa Pack", path: "/mawa-pack" },
    // { title: "Add Mawa", path: "/add-mawa" },
    { title: "User Details", path: "/user-details" },
    // { title: "Orders", path: "/order-pending" },
    { title: "History", path: "/history" },
    { title: "Privacy Policy", path: "/privacy-policy" },
    { title: "Terms Conditions", path: "/terms-conditions" },
    { title: "ContactUs", path: "/contact-us" },
  ];

  // Set the selected index based on the current pathname
  useEffect(() => {
    const index = routes.findIndex((route) => route.path === pathname);
    if (index !== -1) {
      setSelectedIndex(index);
    }
  }, [pathname]);

  const handleCollapse = (setter, current) => {
    setter(!current);
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {routes.map((val, index) => {
          if (val.content) {
            const isOpened =
              val.title === "Diamond" ? isDiamondOpened : isPackageOpened;
            const setIsOpened =
              val.title === "Diamond" ? setIsDiamondOpened : setIsPackageOpened;

            return (
              <li className="nav-item" key={index}>
                <Link
                  className={isOpened ? "nav-link" : "nav-link collapsed"}
                  data-bs-target={`#${val.title.toLowerCase()}-nav`}
                  data-bs-toggle="collapse"
                  onClick={() => handleCollapse(setIsOpened, isOpened)}
                >
                  <i
                    className={
                      val.title === "Diamond" ? "bi bi-gem" : "bi bi-boxes"
                    }
                  ></i>
                  <span>{val.title}</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
                <ul
                  id={`${val.title.toLowerCase()}-nav`}
                  className={`nav-content collapse ${isOpened ? "show" : ""}`}
                  data-bs-parent="#sidebar-nav"
                >
                  {val.content.map((item, subIndex) => (
                    <li key={subIndex} className="nav-item">
                      <Link
                        to={item.path}
                        className={
                          selectedSubIndex === subIndex &&
                          selectedIndex === index
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => {
                          setSelectedIndex(index);
                          setSelectedSubIndex(subIndex);
                        }}
                      >
                        <i className="bi bi-circle-fill"></i>
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            );
          }

          return (
            <li className="nav-item" key={index}>
              <Link
                className={
                  selectedIndex === index ? "nav-link active" : "nav-link"
                }
                to={val.path}
                onClick={() => {
                  setSelectedIndex(index);
                  setSelectedSubIndex(null);
                }}
              >
                {val.title === "Dashboard" ? (
                  <i className="bi bi-receipt"></i>
                ) : val.title === "Chuna" ? (
                  <i className="bi bi-boxes"></i>
                ) : val.title === "Mawa Pack" ? (
                  <i className="bi bi-boxes"></i>
                ) : val.title === "Add Mawa" ? (
                  <i className="bi bi-boxes"></i>
                ) : val.title === "Orders" ? (
                  <i class="bi bi-box"></i>
                ) : val.title === "History" ? (
                  <i class="bi bi-clock-history"></i>
                ) : val.title === "User Details" ? (
                  <i class="bi bi-people"></i>
                ) : val.title === "Privacy Policy" ? (
                  <i class="bi bi-people"></i>
                ) :  val.title === "Terms Conditions" ? (
                  <i class="bi bi-people"></i>
                ): val.title === "ContactUs" ? (
                  <i class="bi bi-people"></i>
                ):null}
                <span>{val.title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
