import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import constants, { saveUserLocally } from "../../utils/constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchDataFromAPI } from "../../utils/api";
import { toast } from "react-toastify";
import CommonButton from "../../components/CommonButton/CommonButton";
import Footer from "../../components/Footer/Footer";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const staticEmail = "test@gmail.com";
  const staticPassword = "12345678";

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Please enter your email."),
      password: Yup.string()
        .matches(/^\S*$/, "Password should not contain spaces")
        .required("Please enter your password."),
    }),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const { email, password } = values;

      setLoading(true);

      if (email.trim() === staticEmail && password.trim() === staticPassword) {
        toast.success("Login successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });

        const userData = {
          email: staticEmail,
          password: staticPassword,
        };
        
        saveUserLocally(JSON.stringify(userData));
        localStorage.setItem(constants.USER, JSON.stringify(userData));
        navigate("/dashboard");
      }
      else {
        toast.error("Invelid Login", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
      setLoading(false);

      }
      // } else {
      //   try {
      //     const body = {
      //       email: email.trim(),
      //       password: password.trim(),
      //     };

      //     const response = await fetchDataFromAPI(
      //       "admin/login",
      //       "post",
      //       body,
      //       ""
      //     );
      //     toast.success("Login successfully ✅", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: "bg-success text-white",
      //     });
      //     navigate("/dashboard");

      //     saveUserLocally(JSON.stringify(response?.data));
      //   } catch (error) {
      //     console.log("error", error);
      //     toast.error("Invalid login details", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: "bg-danger text-white",
      //     });
      //     setLoading(false);
      //   }
      // }
    },
  });

  useEffect(() => {
    const USER = localStorage.getItem(constants.USER);
    if (USER) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="card-title fs-5 text-center d-none d-lg-block fw-bold">
                        MAVA
                      </span>
                      <h5 className="card-title text-center pb-0">
                        Login to Your Account
                      </h5>
                    </div>
                    <form
                      className="row g-3 needs-validation"
                      onSubmit={formik.handleSubmit}
                      noValidate
                    >
                      <div className="col-12">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <div className="input-group has-validation">
                          <input
                            placeholder="Enter an email"
                            type="text"
                            name="email"
                            className={`form-control ${
                              formik.touched.email && formik.errors.email
                                ? "is-invalid"
                                : ""
                            }`}
                            id="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="invalid-feedback">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Password
                        </label>

                        <input
                          placeholder="Password"
                          type={showPass ? "text" : "password"}
                          name="password"
                          className={`form-control ${
                            formik.touched.password && formik.errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                          id="yourPassword"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div className="invalid-feedback">
                            {formik.errors.password}
                          </div>
                        )}
                      </div>

                      <CommonButton
                        onClick={formik.handleSubmit}
                        loading={loading}
                        buttonText="Login"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer /> 
    </main>
  );
};

export default LoginPage;
