import React, { useEffect, useState } from "react";
import Processing from "../../components/Processing/Processing";
import { CChart } from "@coreui/react-chartjs";
import { fetchDataFromAPI } from "../../utils/api";

const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    setLoading(true);
    fetchDataFromAPI("dashboard_data", "get", "", "")
      .then((res) => {
        console.log("res", res);
        setData(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        console.log("error", error);
      });
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <h1 className="">Dashboard</h1>
        <div className="row justify-content-center mt-4">
          <div className="col-lg-4 col-md-4">
            <div
              className="card custom-card mb-3 text-center d-flex justify-content-center align-items-center p-3"
              style={{
                backgroundColor: "#add0a0",
                borderColor: "#6aa84f",
                borderWidth: 2,
              }}
            >
              <div className="card-body">
                <h2 className="card-text" style={{ fontWeight: "bolder" }}>
                  {data?.totalUserCount}
                </h2>
                <p
                  className="card-title"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Total User
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div
              className="card custom-card text-black mb-3 text-center d-flex justify-content-center align-items-center p-3"
              style={{
                backgroundColor: "#fad2a8",
                borderColor: "#e79138",
                borderWidth: 2,
              }}
            >
              <div className="card-body">
                <h2 className="card-text" style={{ fontWeight: "bolder" }}>
                  {data?.currentMonthUserCount}
                </h2>
                <p
                  className="card-title"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  This Month User
                </p>
              </div>
            </div>
          </div>
        </div>

        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              {/* <a href="index.html">Home</a> */}
              {/* <li className="breadcrumb-item active">Dashboard</li> */}
            </li>
            {/* <li className="breadcrumb-item active">Dashboard</li> */}
          </ol>
        </nav>
      </div>
      {/* <!-- End Page Title --> */}

      <section className="section dashboard">
        <div className="row justify-content-center">
          <div className="col-lg-8 ly__auto">
            <div className="col-xxl-12 col-md-12 ">
              <CChart
                type="line"
                data={{
                  labels: [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ],
                  datasets: [
                    {
                      label: "Last Week",
                      backgroundColor: "#add0a0",
                      borderColor: "#6aa84f",
                      pointBackgroundColor: "#add0a0",
                      pointBorderColor: "#fff",
                      data: data?.lastWeekData,
                    },
                    {
                      label: "Current Week",
                      backgroundColor: "#fad2a8",
                      borderColor: "#e79138",
                      pointBackgroundColor: "#fad2a8",
                      pointBorderColor: "#fff",
                      data: data?.currentWeekData,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        className: "rgba(44, 56, 74, 0.95)",
                      },
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          let label = context.dataset.label || "";
                          if (label) {
                            label += `: ${context.parsed.y}`;
                          }
                          return label;
                        },
                      },
                      position: "average",
                      mode: "index",
                      intersect: true,
                    },
                  },

                  scales: {
                    x: {
                      grid: {
                        color: "rgba(0, 0, 21, 0.175)",
                      },
                      ticks: {
                        color: "rgba(44, 56, 74, 0.95)",
                      },
                    },
                    y: {
                      grid: {
                        color: "rgba(0, 0, 21, 0.175)",
                      },
                      ticks: {
                        color: "rgba(44, 56, 74, 0.95)",
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default DashboardPage;
