import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicyPage = () => {
  return (
    <div>
      {/* Header with Breadcrumbs */}
      {/* ... */}

      {/* Main Content */}
      <div className="card mx-3 mt-3">
        <div className="card-body">
          <h2 className="card-title">Privacy Policy</h2>
          <p>
            Your privacy is important to us. It is our policy to respect your
            privacy regarding any information we may collect from you across
            our website.
          </p>
          <p>
            We only ask for personal information when we truly need it to
            provide a service to you. We collect it by fair and lawful means,
            with your knowledge and consent.
          </p>
          <p>
            We also let you know why we’re collecting it and how it will be
            used. We only retain collected information for as long as necessary
            to provide you with your requested service.
          </p>
          <p>
            What data we store, we’ll protect within commercially acceptable
            means to prevent loss and theft, as well as unauthorized access,
            disclosure, copying, use or modification.
          </p>
          <p>
            We don’t share any personally identifying information publicly or
            with third-parties, except when required to by law.
          </p>
          <p>
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            practices of these sites, and cannot accept responsibility or
            liability for their respective privacy policies.
          </p>
          <p>
            You are free to refuse our request for your personal information,
            with the understanding that we may be unable to provide you with
            some of your desired services.
          </p>
          <p>
            Your continued use of our website will be regarded as acceptance of
            our practices around privacy and personal information. If you have
            any questions about how we handle user data and personal
            information, feel free to contact us.
          </p>

          <h2 className="card-title mt-4">Payment Integration and Refund Policy</h2>
          <p>
            We use secure third-party payment processing services to handle
            transactions. These services adhere to the latest security
            standards and are PCI-DSS compliant to ensure your payment
            information is safe.
          </p>
          <p>
            All transactions are encrypted, and we do not store your payment
            information on our servers. We only retain transaction records as
            required for billing and auditing purposes.
          </p>
          <p>
            In the event of a payment dispute or request for a refund, please
            contact our customer support team within 30 days of the transaction.
            Refunds will be processed in accordance with our refund policy,
            which states that we will issue a refund for services not rendered
            or for any erroneous charges.
          </p>
          <p>
            Please note that refunds may take up to 5-7 working days to
            reflect in your account, depending on your payment provider.
          </p>

          <h2 className="card-title mt-4">Terms and Conditions</h2>
          <p>
            Please read these terms and conditions carefully before using our website. By accessing or using our website, you agree to be bound by these terms. If you do not agree with any part of the terms, you may not access the website.
          </p>

          <h3 className="card-title mt-4">Privacy Policy</h3>
          <p>
            Your privacy is important to us. It is our policy to respect your privacy regarding any information we may collect from you across our website.
          </p>

          <h3 className="card-title mt-4">Refunds/Cancellations</h3>
          <p>
            If you are not satisfied with your purchase, you may request a refund within 30 days of the transaction. Refunds will be processed within 5-7 working days. Please contact our support team to initiate the refund process.
          </p>

          <h3 className="card-title mt-4">Pricing</h3>
          <p>
            All prices listed on our website are inclusive of all applicable taxes. We reserve the right to change prices at any time without prior notice.
          </p>

          <h3 className="card-title mt-4">Contact Us</h3>
          <p>
            If you have any questions or concerns, please contact us at:
          </p>
          <p>Email: mawa.comrozerpay@gmail.com</p>
          <p>Phone: +91 97144 95387</p>
          <p>Address: Krishna industry,near simada police chowkey,saniya hemad,Surat Gujarat- 395006</p>

          <h3 className="card-title mt-4">Products/Services</h3>
          <p>
            Our products and services are described on our website. We make every effort to ensure that the information provided is accurate and up-to-date. However, we do not warrant that the product/service descriptions or other content on our website are complete, reliable, current, or error-free.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
