import React, { useEffect, useState } from "react";
import CommonButton from "../../components/CommonButton/CommonButton";
import Processing from "../../components/Processing/Processing";
import { Link, useNavigate } from "react-router-dom";
import { FormGroup } from "react-bootstrap";
import { fetchDataFromAPI } from "../../utils/api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

const AddMawasPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState(null);

  const [chuna, setChuna] = useState();
  const [mawaPack, setMawaPack] = useState();

  useEffect(() => {
    getChuna();
    getWakaPack();
  }, []);

  const getChuna = () => {
    fetchDataFromAPI("mawa/get-all-chuna", "get", "", "")
      .then((res) => {
        setChuna(res?.data);
        setLoading(false);
        console.log("res chuna", res?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const getWakaPack = () => {
    fetchDataFromAPI("mawa/get-all-mawa-pack", "get", "", "")
      .then((res) => {
        setMawaPack(res?.data);
        setLoading(false);
        console.log("res", res?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    mawa_images: Yup.mixed().required("Please select image"),
    mawa_name: Yup.string().required("Please enter mawa name"),
    mawa_price: Yup.string().required("Please enter chuna price"),
    chuno: Yup.array()
      .min(1, "Choose at least one chuna")
      .required("Please select chuna"),

    mawa_pack: Yup.string().required("Please select mawa pack"),
  });

  const formik = useFormik({
    initialValues: {
      mawa_images: null,
      mawa_name: "",
      mawa_price: "",
      chuno: [],
      mawa_pack: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);

      //   setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        mawa_images: url,
        mawa_name: values?.mawa_name,
        mawa_price: values.mawa_price,
        chuno: values?.chuno,
        mawa_pack: values?.mawa_pack,
      };

      console.log("body", body);

        fetchDataFromAPI("mawa/add-mawa", "post", body, "")
          .then((res) => {
            setLoading(false);

            toast.success("Added successfully ✅", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });
            console.log("content res:", res?.data);
            formik.resetForm();
            navigate("/add-mawa");
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-danger text-white",
            });
            console.log("Error while updating member: ", error);
          });
    },
  });

  const handleFileChange = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);


    console.log("first",fileArray)
    formik.setFieldValue("mawa_images", fileArray);
  
    const uploadPromises = fileArray.map((file) => {
      const data = new FormData();
      data.append("image", file);
  
      return fetchDataFromAPI("mawa/upload", "post", data, "")
        .then((res) => {
          console.log("Upload successful:", res.data);
          return res.data;
        })
        .catch((error) => {
          console.error("Error uploading file:", file.name, error);
          return null;
        });
    });
  
    setLoading(true);
  
    Promise.all(uploadPromises)
      .then((results) => {
        const successfulUploads = results.filter((url) => url !== null);
        setURL(successfulUploads);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
        setLoading(false);
      });
  };
  

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/add-mawa">Mawa</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Add Mawa</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="reg" className="form-label">
                          Select Images
                        </label>
                        <FormGroup>
                          <input
                            className={`form-control form-control-file ${
                              formik.touched.mawa_images &&
                              formik.errors.mawa_images &&
                              !url
                                ? "is-invalid"
                                : ""
                            }`}
                            type="file"
                            name="mawa_images"
                            accept="image/*"
                            id="image"
                            multiple
                            onChange={handleFileChange}
                          />
                        </FormGroup>
                        {formik.touched.mawa_images &&
                          formik.errors.mawa_images &&
                          !url && (
                            <div className="text-danger">
                              {formik.errors.mawa_images}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="mawa_name" className="form-label">
                          Mawa Name
                        </label>

                        <input
                          name="mawa_name"
                          type="text"
                          className="form-control"
                          id="mawa_name"
                          value={formik.values.mawa_name}
                          onChange={(e) => {
                            const value = e.target.value?.trimStart();
                            // Allow only letters (both uppercase and lowercase) and spaces
                            const sanitizedValue = value.replace(
                              /[^a-zA-Z\s]/g,
                              ""
                            );
                            formik.setFieldValue("mawa_name", sanitizedValue);
                          }}
                        />

                        {formik.touched.mawa_name &&
                          formik.errors.mawa_name && (
                            <div className="text-danger">
                              {formik.errors.mawa_name}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="mawa_price" className="form-label">
                          Mawa Price
                        </label>

                        <input
                          name="mawa_price"
                          type="text"
                          className="form-control"
                          id="mawa_price"
                          value={formik.values.mawa_price}
                          onChange={(e) => {
                            const value = e.target.value?.trimStart();
                            // Allow only numbers
                            const sanitizedValue = value.replace(/[^0-9]/g, "");
                            formik.setFieldValue("mawa_price", sanitizedValue);
                          }}
                        />

                        {formik.touched.mawa_price &&
                          formik.errors.mawa_price && (
                            <div className="text-danger">
                              {formik.errors.mawa_price}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="chuno" className="form-label">
                          Select Chuna
                        </label>
                        <Select
                          isMulti
                          options={chuna}
                          getOptionLabel={(option) => option?.chuna_name}
                          getOptionValue={(option) => option?._id}
                          onChange={(val) =>
                            formik.setFieldValue(
                              "chuno",
                              val?.map((item) => item?._id)
                            )
                          }
                        />

                        {formik.touched.chuno && formik.errors.chuno && (
                          <div className="text-danger">
                            {formik.errors.chuno}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="mawa_pack" className="form-label">
                          Mawa Pack
                        </label>
                        <Select
                          options={mawaPack}
                          getOptionLabel={(option) => option?.mawa_pack_qty}
                          getOptionValue={(option) => option?._id}
                          onChange={(val) =>
                            formik.setFieldValue(
                              "mawa_pack",
                              val ? val._id : null
                            )
                          }
                        />

                        {formik.touched.mawa_pack &&
                          formik.errors.mawa_pack && (
                            <div className="text-danger">
                              {formik.errors.mawa_pack}
                            </div>
                          )}
                      </div>
                    </div>

                    <CommonButton
                      onClick={formik.handleSubmit}
                      // isValid={formik.isValid}
                      loading={loading}
                      buttonText="Add"
                    />
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AddMawasPage;
