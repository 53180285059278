
import React from 'react'
import { Link } from 'react-router-dom'

const ContactUs = () => {
  return (
    <div >
    {/* Header with Breadcrumbs */}


    {/* Main Content */}
    <div className="card mx-3 mt-3">
      <div className="card-body">
        <h2 className="card-title">Contact Us</h2>
        <p>
          If you have any questions or need further information, feel free to contact us.
        </p>
        
        {/* Contact Information */}
        <div className="mb-4">
          <h3>Contact Information</h3>
          <p><strong>Phone:</strong> +91 97144 95387</p>
          <p><strong>Email:</strong> mawa.comrozerpay@gmail.com</p>
        </div>

        {/* Contact Form */}
        <div>
          <h3>Send Us a Message</h3>
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" className="form-control" id="name" required />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" className="form-control" id="email" required />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea className="form-control" id="message" rows="4" required></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ContactUs