import React, { useEffect, useState } from "react";
import Processing from "../../components/Processing/Processing";
import { fetchDataFromAPI } from "../../utils/api";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { toast } from "react-toastify";
import moment from "moment";

const UserListPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  // this use Effect for delete id
  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  useEffect(() => {
    setLoading(true);

    getAdmins();
  }, []);

  const getAdmins = () => {
    fetchDataFromAPI("user-details", "get", "", "")
      .then((res) => {
        setData(res?.allUserDetails);
        setLoading(false);
        console.log("res", res);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  // hide delete modal function
  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  // api for delete
  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(`user-details?id=${deleteId}`, "delete", "", "")
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getAdmins();
        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Content error: ", error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        setLoading(false);
      });
  };

  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Users</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <form className="mx-3">
          <div className="row mb-3"></div>
        </form>
        <div className="card-body">
          <div className="d-flex  ">
            <h3 className=" font-weight-bold ">Users List</h3>
            {/* <div className="col d-flex justify-content-end  ">
              <button
                type="submit"
                className="btn-custom-btn"
                onClick={() => navigate("/add-chuna")}
              >
                Add Chuna
              </button>
            </div> */}
          </div>
          <hr />
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap  align-items-end table-hover">
              <thead>
                <tr className="">
                  <th scope="col">No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Daily</th>
                  <th scope="col">Balance</th>
                  <th scope="col">Pack of</th>
                  <th scope="col">Area</th>
                  <th scope="col">Address</th>
                  <th scope="col">Location</th>
                  <th scope="col">Join Date</th>

                  {/* <th scope="col">View</th> */}
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  return (
                    <tr className="align-middle">
                      <th>{index + 1}</th>

                        <td>{item?.name + " " + item?.lastName}</td>
                        <td>{item?.mobileNumber}</td>
                       <td>{item?.isDaily === true ? "YES" : "NO"}</td>
                        <td>₹{item?.walletBalance}</td>
                        <td></td>
                        {/* <td>{item?.packMawaOrdered ? item?.packMawaOrdered : "-"}</td> */}
                        <td>{item?.area ? item?.area : "-" }</td>
                        <td>{item?.address ? item?.address : "-" }</td>
                        <td>{item?.location ? item?.location : "-" }</td> 
                      <td>{moment(item?.date).format('MMMM Do YYYY, h:mm:ss a')}</td>

                      {/* <td className="">
                        <div className="d-flex">
                          <div
                            className="edit-icon  me-2"
                            role="button"
                            onClick={() =>
                              navigate(`/edit-chuna/${item?._id}`)
                            }
                          >
                            <i class="bi bi-pencil-square"></i>
                          </div>
                          <div
                            role="button"
                            className="delete-icon btn btn-sm btn-danger"
                            onClick={() => {
                              setDeleteId(item?._id);
                            }}
                          >
                            <i class="bi bi-trash"></i>
                          </div>
                        </div>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <!-- End Default Table Example --> */}
        </div>
        <DeleteModal
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={deleteContent}
          title="Delete Chuna"
          body="Are you sure you want to delete this record?"
        />
      </div>
    </div>
  );
};

export default UserListPage;
