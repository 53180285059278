import React, { useState } from "react";
import CommonButton from "../../components/CommonButton/CommonButton";
import Processing from "../../components/Processing/Processing";
import { Link, useNavigate } from "react-router-dom";
import { FormGroup } from "react-bootstrap";
import { fetchDataFromAPI } from "../../utils/api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddChunaPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState(null);
  const validationSchema = Yup.object().shape({
    chuna_image: Yup.mixed().required("Please select image"),
    chuna_name: Yup.string().required("Please enter chuna name"),
    chuna_price: Yup.string().required("Please enter chuna price"),
    chuna_qty: Yup.string().required("Please enter chuna quantity"),
  });

  const formik = useFormik({
    initialValues: {
      chuna_image: null,
      chuna_name: "",
      chuna_price: "",
      chuna_qty: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);

      //   setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        chuna_image: url,
        chuna_name: values?.chuna_name,
        chuna_price: values.chuna_price,
        chuna_qty: values?.chuna_qty,
      };

      fetchDataFromAPI("mawa/add-chuna", "post", body, "")
        .then((res) => {
          setLoading(false);

          toast.success("Added successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          console.log("content res:", res?.data);
          formik.resetForm();
          navigate("/chuna");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating member: ", error);
        });
    },
  });

  const handleFileChange = (e) => {
    // console.log("e?.target?.files[0]: ", e?.target?.files[0]);
    // setFile(e?.target?.files[0]);
    const image = e?.target?.files[0];
    formik.setFieldValue("chuna_image", image);
    const data = new FormData();
    data.append("image", image);
    setLoading(true);
    fetchDataFromAPI("mawa/upload", "post", data, "")
      .then((res) => {
        console.log("res: ", res?.data);
        setURL(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setLoading(false);
      });
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/chuna">Chuna</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Add Chuna</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="reg" className="form-label">
                          Selete Image
                        </label>
                        <FormGroup>
                          <input
                            className={`form-control form-control-file  ${
                              formik.touched.chuna_image &&
                              formik.errors.chuna_image &&
                              !url
                                ? "is-invalid"
                                : ""
                            }`}
                            type="file"
                            name="chuna_image"
                            accept="image/*"
                            id="image"
                            // onChange={(e) =>
                            //   formik.setFieldValue('image', e.target.files[0])
                            // }
                            onChange={handleFileChange}
                            // {...formikcsv.getFieldProps('csvFile')}
                          />
                        </FormGroup>
                        {formik.touched.chuna_image &&
                          formik.errors.chuna_image &&
                          !url && (
                            <div className="text-danger">
                              {formik.errors.chuna_image}
                            </div>
                          )}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="chuna_name" className="form-label">
                          Chuna Name
                        </label>

                        <input
                          name="chuna_name"
                          type="text"
                          className="form-control"
                          id="chuna_name"
                          value={formik.values.chuna_name}
                          onChange={(e) => {
                            const value = e.target.value?.trimStart();
                            // Allow only letters (both uppercase and lowercase) and spaces
                            const sanitizedValue = value.replace(
                              /[^a-zA-Z\s]/g,
                              ""
                            );
                            formik.setFieldValue("chuna_name", sanitizedValue);
                          }}
                        />

                        {formik.touched.chuna_name &&
                          formik.errors.chuna_name && (
                            <div className="text-danger">
                              {formik.errors.chuna_name}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="chuna_price" className="form-label">
                          Chuna Price
                        </label>

                        <input
                          name="chuna_price"
                          type="text"
                          className="form-control"
                          id="chuna_price"
                          value={formik.values.chuna_price}
                          onChange={(e) => {
                            const value = e.target.value?.trimStart();
                            // Allow only numbers
                            const sanitizedValue = value.replace(/[^0-9]/g, "");
                            formik.setFieldValue("chuna_price", sanitizedValue);
                          }}
                        />

                        {formik.touched.chuna_price &&
                          formik.errors.chuna_price && (
                            <div className="text-danger">
                              {formik.errors.chuna_price}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="chuna_qty" className="form-label">
                          Chuna Quantity
                        </label>

                        <input
                          name="chuna_qty"
                          type="text"
                          className="form-control"
                          id="chuna_qty"
                          value={formik.values.chuna_qty}
                          onChange={(e) => {
                            const value = e.target.value?.trimStart();
                            // Allow only numbers
                            const sanitizedValue = value.replace(/[^0-9]/g, "");
                            formik.setFieldValue("chuna_qty", sanitizedValue);
                          }}
                        />

                        {formik.touched.chuna_qty &&
                          formik.errors.chuna_qty && (
                            <div className="text-danger">
                              {formik.errors.chuna_qty}
                            </div>
                          )}
                      </div>
                    </div>

                    <CommonButton
                      onClick={formik.handleSubmit}
                      // isValid={formik.isValid}
                      loading={loading}
                      buttonText="Add"
                    />
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AddChunaPage;
