
import React from 'react'
import { Link } from 'react-router-dom'

const Termsconditions = () => {
  return (
    <div >
    {/* Header with Breadcrumbs */}


    {/* Main Content */}
    <div className="card mx-3 mt-3">
      <div className="card-body">
        <h2 className="card-title">Terms and Conditions</h2>
        <p>
          Welcome to our website. If you continue to browse and use this
          website, you are agreeing to comply with and be bound by the
          following terms and conditions of use, which together with our
          privacy policy govern [Your Company Name]'s relationship with you in
          relation to this website.
        </p>
        <p>
          The term 'you' refers to the user or viewer of our website. The use
          of this website is subject to the following terms of use:
        </p>
        <h3>Use of the Website</h3>
        <p>
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
        </p>
        <p>
          Neither we nor any third parties provide any warranty or guarantee
          as to the accuracy, timeliness, performance, completeness or
          suitability of the information and materials found or offered on
          this website for any particular purpose. You acknowledge that such
          information and materials may contain inaccuracies or errors and we
          expressly exclude liability for any such inaccuracies or errors to
          the fullest extent permitted by law.
        </p>
        <h3>Intellectual Property</h3>
        <p>
          This website contains material which is owned by or licensed to us.
          This material includes, but is not limited to, the design, layout,
          look, appearance and graphics. Reproduction is prohibited other than
          in accordance with the copyright notice, which forms part of these
          terms and conditions.
        </p>
        <h3>Links to Other Websites</h3>
        <p>
          From time to time, this website may also include links to other
          websites. These links are provided for your convenience to provide
          further information. They do not signify that we endorse the
          website(s). We have no responsibility for the content of the linked
          website(s).
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          Your use of any information or materials on this website is entirely
          at your own risk, for which we shall not be liable. It shall be your
          own responsibility to ensure that any products, services or
          information available through this website meet your specific
          requirements.
        </p>
      </div>
    </div>
  </div>
  )
}

export default Termsconditions