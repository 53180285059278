import React, { useEffect, useState } from "react";
import CommonButton from "../../components/CommonButton/CommonButton";
import Processing from "../../components/Processing/Processing";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormGroup } from "react-bootstrap";
import { fetchDataFromAPI } from "../../utils/api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

const EditMawaPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState(null);
  const [data, setData] = useState();

  const { id } = useParams();
  useEffect(() => {
    getMavaPack();
  }, []);

  const getMavaPack = () => {
    setLoading(true);
    fetchDataFromAPI(`mawa/get-mawa-pack-by-id?id=${id}`, "get", "", "")
      .then((res) => {
        setData(res?.data);
        formik.setFieldValue("mawa_pack_images", res?.data?.mawa_pack_images);
        formik.setFieldValue("mawa_name", res?.data?.mawa_name);
        formik.setFieldValue("discount", res?.data?.discount);
        formik.setFieldValue("mawa_pack_price", res?.data?.mawa_pack_price);
        formik.setFieldValue("mawa_pack_qty", res?.data?.mawa_pack_qty);
        setLoading(false);
        console.log("res", res?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    mawa_pack_images: Yup.mixed().required("Please select image"),
    discount: Yup.string().required("Please enter discount"),
    mawa_name: Yup.string().required("Please enter mawa pack name"),
    mawa_pack_price: Yup.string().required("Please enter mawa pack price"),
    mawa_pack_qty: Yup.string().required("Please enter mawa pack quantity"),
  });

  const formik = useFormik({
    initialValues: {
      mawa_pack_images: null,
      mawa_name: "",
      discount: "",
      mawa_pack_price: "",
      mawa_pack_qty: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);

      //   setLoading(true);
      //   const admin = JSON.parse(getUser());
      const body = {
        mawa_pack_images: url ? url : data?.mawa_pack_images,
        discount: values?.discount,
        mawa_name: values?.mawa_name,
        mawa_pack_price: values.mawa_pack_price,
        mawa_pack_qty: values?.mawa_pack_qty,
      };

      fetchDataFromAPI(`mawa/update_mawa_pack?id=${id}`, "post", body, "")
        .then((res) => {
          setLoading(false);

          toast.success("Added successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          console.log("content res:", res?.data);
          formik.resetForm();
          navigate("/mawa-pack");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          console.log("Error while updating member: ", error);
        });
    },
  });

  const handleFileChange = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);

    console.log("first", fileArray);
    formik.setFieldValue("mawa_pack_images", fileArray);

    const uploadPromises = fileArray.map((file) => {
      const data = new FormData();
      data.append("image", file);

      return fetchDataFromAPI("mawa/upload", "post", data, "")
        .then((res) => {
          console.log("Upload successful:", res.data);
          return res.data;
        })
        .catch((error) => {
          console.error("Error uploading file:", file.name, error);
          return null;
        });
    });

    setLoading(true);

    Promise.all(uploadPromises)
      .then((results) => {
        const successfulUploads = results.filter((url) => url !== null);
        setURL(successfulUploads);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
        setLoading(false);
      });
  };

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/mawa-pack">Mawa Pack</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit Mawa Pack</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="reg" className="form-label">
                          Selete Image
                        </label>
                        <FormGroup>
                          <input
                            className={`form-control form-control-file ${
                              formik.touched.mawa_pack_images &&
                              formik.errors.mawa_pack_images &&
                              !url
                                ? "is-invalid"
                                : ""
                            }`}
                            type="file"
                            multiple
                            name="mawa_pack_images"
                            accept="image/*"
                            id="image"
                            // onChange={(e) =>
                            //   formik.setFieldValue('image', e.target.files[0])
                            // }
                            onChange={handleFileChange}
                            // {...formikcsv.getFieldProps('csvFile')}
                          />
                        </FormGroup>
                        {formik.touched.mawa_pack_images &&
                          formik.errors.mawa_pack_images &&
                          !url && (
                            <div className="text-danger">
                              {formik.errors.mawa_pack_images}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="mawa_name" className="form-label">
                          Mawa Pack
                        </label>

                        <input
                          name="mawa_name"
                          type="text"
                          className="form-control"
                          id="mawa_name"
                          value={formik.values.mawa_name}
                          onChange={(e) => {
                            // Trim leading whitespace
                            const value = e.target.value?.trimStart();

                            // Allow only alphanumeric characters
                            const sanitizedValue = value.replace(
                              /[^a-zA-Z0-9\s]/g,
                              ""
                            );

                            // Set the sanitized value to the form field
                            formik.setFieldValue("mawa_name", sanitizedValue);
                          }}
                        />

                        {formik.touched.mawa_name &&
                          formik.errors.mawa_name && (
                            <div className="text-danger">
                              {formik.errors.mawa_name}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="discount" className="form-label">
                          Discount
                        </label>

                        <input
                          name="discount"
                          type="text"
                          className="form-control"
                          id="discount"
                          value={formik.values.discount}
                          onChange={(e) => {
                            const value = e.target.value?.trimStart();
                            // Allow only numbers
                            const sanitizedValue = value.replace(/[^0-9]/g, "");
                            formik.setFieldValue("discount", sanitizedValue);
                          }}
                        />

                        {formik.touched.discount && formik.errors.discount && (
                          <div className="text-danger">
                            {formik.errors.discount}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="mawa_pack_price" className="form-label">
                          Mawa Pack Price
                        </label>

                        <input
                          name="mawa_pack_price"
                          type="text"
                          className="form-control"
                          id="mawa_pack_price"
                          value={formik.values.mawa_pack_price}
                          onChange={(e) => {
                            const value = e.target.value?.trimStart();
                            // Allow only numbers
                            const sanitizedValue = value.replace(/[^0-9]/g, "");
                            formik.setFieldValue(
                              "mawa_pack_price",
                              sanitizedValue
                            );
                          }}
                        />

                        {formik.touched.mawa_pack_price &&
                          formik.errors.mawa_pack_price && (
                            <div className="text-danger">
                              {formik.errors.mawa_pack_price}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="mawa_pack_qty" className="form-label">
                          Mawa Pack Quantity
                        </label>

                        <input
                          name="mawa_pack_qty"
                          type="text"
                          className="form-control"
                          id="mawa_pack_qty"
                          value={formik.values.mawa_pack_qty}
                          onChange={(e) => {
                            const value = e.target.value?.trimStart();
                            // Allow only numbers
                            const sanitizedValue = value.replace(/[^0-9]/g, "");
                            formik.setFieldValue(
                              "mawa_pack_qty",
                              sanitizedValue
                            );
                          }}
                        />

                        {formik.touched.mawa_pack_qty &&
                          formik.errors.mawa_pack_qty && (
                            <div className="text-danger">
                              {formik.errors.mawa_pack_qty}
                            </div>
                          )}
                      </div>
                    </div>

                    <CommonButton
                      onClick={formik.handleSubmit}
                      // isValid={formik.isValid}
                      loading={loading}
                      buttonText="Edit"
                    />
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditMawaPage;
