// react
import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

// css
import "./assets/css/style.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Header from "./components/header/header";
import Sidebar from "./components/sidebar/sidebar";
import Error from "./pages/Error/Error";

import routes from "./routes/routes";
import LoginPage from "./pages/LoginPage/LoginPage";
import { ToastContainer } from "react-toastify";
import ContactUs from "./pages/ContactUsPage/ContactUs";
import Termsconditions from "./pages/TermsConditionsPage/Termsconditions";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";

const App = () => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname !== "/login" &&
        pathname !== "/404" &&
        pathname !== "/privacy-policy" &&
        pathname !== "/terms-conditions" &&
        pathname !== "/contact-us" && <Header />}
      {pathname !== "/login" &&
        pathname !== "/404" &&
        pathname !== "/privacy-policy" &&
        pathname !== "/terms-conditions" &&
        pathname !== "/contact-us" && <Sidebar />}
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-conditions" element={<Termsconditions />} />
        <Route path="/contact-us" element={<ContactUs />} />

        {routes.map(({ path, element }, index) => (
          <Route key={index} path={path} element={element} />
        ))}
        <Route path="/404" element={<Error />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </>
  );
};

export default App;
