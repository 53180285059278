import React, { useEffect, useState } from "react";
import Processing from "../../components/Processing/Processing";
import { fetchDataFromAPI } from "../../utils/api";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

const ViewOnHoldPage = () => {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
  
    const [orderDetail, setOrderDetails] = useState();
  
    useEffect(() => {
      getOrderDetail();
    }, []);
  
    const getOrderDetail = () => {
      setLoading(true);
      fetchDataFromAPI(`order/order-by-id?orderId=${id}`, "", "", "")
        .then((res) => {
          console.log("res", res);
          setOrderDetails(res);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    };
  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/onhold">On Hold Order</Link>
            </li>

            <li className="breadcrumb-item active">
              <span>{orderDetail?.customerName}</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content pt-2">
                <div
                  className="tab-pane fade show active profile-overview"
                  id="profile-overview"
                >
                  <h5 className="card-title mt-3">Customer Details</h5>
                  <hr />{" "}
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">
                      Customer Name
                    </div>
                    <div className="col-lg-9 col-md-8">
                      {orderDetail?.customerName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">
                      Mobile Number
                    </div>
                    <div className="col-lg-9 col-md-8">
                      {orderDetail?.customerPhoneNumber}
                    </div>
                  </div>
            
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">Amount</div>
                    <div className="col-lg-9 col-md-8">
                    ₹{orderDetail?.totalAmount}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">Chuna Name</div>
                    <div className="col-lg-9 col-md-8">
                    {orderDetail?.chunaName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">Time Slot</div>
                    <div className="col-lg-9 col-md-8">
                    {orderDetail?.timeSlot === '7 - 10 AM' ? 'Morning' : 'Evening'
                    }
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">Status</div>
                    <div className="col-lg-9 col-md-8">
                    {orderDetail?.status}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">Area</div>
                    <div className="col-lg-9 col-md-8">
                    {orderDetail?.area}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">Address</div>
                    <div className="col-lg-9 col-md-8">
                    {orderDetail?.address}
                    </div>
                  </div>
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default ViewOnHoldPage