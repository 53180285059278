import React, { useEffect, useState } from "react";
import Processing from "../../components/Processing/Processing";
import { fetchDataFromAPI } from "../../utils/api";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";

const OrderPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmOrderId, setConfirmOrderId] = useState(null);
  const [confirmStatus, setConfirmStatus] = useState("");

  const [selectedType, setSelectedType] = useState("");

  const [selected, setSelected] = useState("");
  console.log("selected",selected)

  const handleEvening = (type) => {
    setSelected(type);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const handleStatusChange = (id, status) => {
    setConfirmOrderId(id);
    setConfirmStatus(status);
    setShowConfirmModal(true);
  };

  const confirmStatusChange = () => {
    updateOrderStatus(confirmOrderId, confirmStatus);
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (deleteId) {
      setShowDeleteModal(true);
    } else {
      setShowDeleteModal(false);
    }
  }, [deleteId]);

  useEffect(() => {
    setLoading(true);
    getOrder();
  }, [selectedType,selected]);

  const getOrder = () => {
    fetchDataFromAPI(`order/all-order?areaName=${selectedType}&timeSlot=${selected}`, "get", "", "")
      .then((res) => {
        setData(res?.orders);
        setLoading(false);
        console.log("res", res);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const updateOrderStatus = (id, status) => {
    const body = {
      orderId: id,
      status: status,
    };

    console.log("body",body)
    setLoading(true);
    fetchDataFromAPI(`order/update_status`, "post", body, "")
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getOrder();
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        setLoading(false);
      });
  };

  const hideDeleteModal = () => {
    setDeleteId(null);
  };

  const deleteContent = () => {
    setLoading(true);
    fetchDataFromAPI(`order/delete-order?id=${deleteId}`, "delete", "", "")
      .then((res) => {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getOrder();
        setDeleteId(null);
        setShowDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Content error: ", error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        setLoading(false);
      });
  };

  const openInGoogleMaps = (address) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    window.open(url, '_blank');
  };


  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Order Pending</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <form className="mx-3">
          <div className="row mb-3"></div>
        </form>
        <div className="card-body">
          <div className="d-flex justify-content-center mb-3">
            {/* <button
              className={`btn-custom ${selected === "" ? "active" : ""}`}
              onClick={() => handleEvening("")}
            >
              All
            </button> */}
            <button
              className={`btn-custom ${selected === "7 - 10 AM" ? "active" : ""}`}
              onClick={() => handleEvening("7 - 10 AM")}
            >
              Morning
            </button>
            <button
              className={`btn-custom ${selected === "6 - 11 PM" ? "active" : ""}`}
              onClick={() => handleEvening("6 - 11 PM")}
            >
              Evening
            </button>
          </div>
          <hr />
          <div className="d-flex justify-content-center mb-3">
            <button
              className={`btn-custom ${selectedType === "" ? "active" : ""}`}
              onClick={() => handleTypeChange("")}
            >
              All
            </button>
            <button
              className={`btn-custom ${
                selectedType === "amroli" ? "active" : ""
              }`}
              onClick={() => handleTypeChange("amroli")}
            >
              Amroli
            </button>
            <button
              className={`btn-custom ${
                selectedType === "Mota Varachha" ? "active" : ""
              }`}
              onClick={() => handleTypeChange("Mota Varachha")}
            >
              Mota Varachha
            </button>
            <button
              className={`btn-custom ${
                selectedType === "varachha" ? "active" : ""
              }`}
              onClick={() => handleTypeChange("varachha")}
            >
              Varachha
            </button>
            <button
              className={`btn-custom ${
                selectedType === "katargam" ? "active" : ""
              }`}
              onClick={() => handleTypeChange("katargam")}
            >
              Katargam
            </button>
          </div>
          <hr />
          <div className="table-responsive">
            {data.length === 0 ? (
              <div className="text-center">No orders yet</div>
            ) : (
              <table className="table text-nowrap align-items-end table-hover">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Daily</th>
                    <th scope="col">Mobile No</th>
                    <th scope="col">Chuna Name</th>
                    <th scope="col">Mawa Pack Quantity</th>
                    <th scope="col">Area</th>
                    <th scope="col">Day</th>
                    <th scope="col">Time</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Phone/Doorbell</th>
                    <th scope="col">Status</th>
                    <th scope="col">Address</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data
                     ?.filter((item) => item.status == "Pending")
                    ?.map((item, index) => {
                      return (
                        <tr className="align-middle" key={index}>
                          <th>{index + 1}</th>
                          <td>{item?.customerName}</td>
                          <td>{item?.isDaily ? "YES" : "NO"}</td>
                          <td>{item?.customerPhoneNumber}</td>
                          <td>{item?.chunaName}</td>
                          <td className="text-center">{item?.mawa_pack_quantity}</td>
                          <td>{item?.area}</td>
                          <td>{item?.timeSlot === '7 - 10 AM' ? 'Morning' : 'Evening'}</td>
                          <td>
                            {moment(item?.createdAt).format(
                              "MMM Do YYYY, h:mm:ss a"
                            )}
                          </td>
                          <td>₹{item?.totalAmount}</td>
                          <td>{item?.phoneOrDoorbell}</td>
                          {/* <td>
                            <button
                              className={`btn-status ${
                                item.status === "Completed" ? "active" : ""
                              }`}
                              onClick={() =>
                                handleStatusChange(
                                  item?._id,
                                  item.status === "Completed"
                                    ? "Pending"
                                    : "Completed"
                                )
                              }
                            >
                              {item.status}
                            </button>
                          </td> */}


                          <td>
                            <select
                            className={'btn-status'}
                              value={item.status}
                              onChange={(e) =>
                                handleStatusChange(item?._id, e.target.value)
                              }
                              // className="form-select"
                            >
                              <option value="Pending">Pending</option>
                              <option value="Completed">Completed</option>
                              <option value="Hold">On Hold</option>
                            </select>
                          </td>
                          <td>
                          <button
                              className="btn-custom-btn"
                              onClick={() => openInGoogleMaps(item?.address)}
                            >
                              <i class="bi bi-geo-alt-fill"></i>
                            </button>{' '} {item?.address}
                          </td>
                        
                          <td className="">
                            <div className="d-flex">
                              <div
                                className="edit-icon me-2"
                                role="button"
                                onClick={() =>
                                  navigate(`/view-order-pending/${item?._id}`)
                                }
                              >
                                <i className="bi bi-eye"></i>
                              </div>
                              <div
                                role="button"
                                className="delete-icon btn btn-sm btn-danger"
                                onClick={() => setDeleteId(item?._id)}
                              >
                                <i className="bi bi-trash"></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
          <DeleteModal
            show={showDeleteModal}
            onHide={hideDeleteModal}
            onDelete={deleteContent}
            title="Delete Order"
            body="Are you sure you want to delete this record?"
          />
          <Modal
            centered
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Order Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to "{confirmStatus}" this order?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowConfirmModal(false)}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={confirmStatusChange}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
